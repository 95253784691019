import React from "react";
// contexte global (this.props.data)
import { WithContext } from "../store/DataProvider";
// contexte parcelles (this.props.ctx)
import { WithParcellesActives } from "parcelles/provider";
// react-bootstrap components
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
// custom components
import MapDraw from "../map/mapDraw.js";
import Filters from "../filters/component2";
import Parcelle from "../parcellaire/parcelle.js";
import FloatingSaveButton from "buttons/FloatingSaveButton";
// react-icons
import { MdDelete } from "react-icons/md";

class CreateGroupe extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isModalOpen: false };
    const { id } = this.props.history.location.state;
    let groupeExistant;
    let nouveauGroupe = {
      label: "",
      options: [],
      surfaceTotale: 0,
      checked: false,
    };
    if (id) {
      groupeExistant = props.data.groupesParcelles.find(
        (item) => item.id === id
      );
    }
    if (groupeExistant) {
      this.state.groupe = groupeExistant;
      const parcellesActives = groupeExistant.options.map((parcelle) => {
        return { id: parcelle.properties.id };
      });
      this.props.ctx.setParcellesActives(parcellesActives);
    } else {
      this.state.groupe = nouveauGroupe;
    }
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState((state) => {
      state.groupe[name] = value;
      return state;
    });
  }

  async save() {
    let { groupe } = { ...this.state };
    if (groupe.id === undefined) delete groupe.id;
    // const options = this.props.ctx.parcellesActives.map(
    //   parcelle => parcelle.id
    // );
    // ajouter les données des parcelles
    groupe.options = this.props.ctx.parcellesActives.map((parcelleActive) => {
      return this.props.ctx.parcelles.find(
        (parcelle) => parcelle.properties.id === parcelleActive.id
      );
    });
    // ajouter la surface totale
    groupe.options.forEach((parcelle) => {
      groupe.surfaceTotale =
        Math.round((groupe.surfaceTotale + parcelle.properties.surface) * 100) /
        100;
    });

    if (groupe.label && groupe.options.length > 0) {
      delete groupe.checked;
      if (groupe.id) {
        await this.props.data.updateItem("groupesParcelles", groupe);
      } else {
        await this.props.data.addItem("groupesParcelles", groupe);
      }
      this.goToList();
    } else {
      this.props.data.showNotification({
        type: "danger",
        title: "Le groupe doit avoir un nom et au moins une parcelle.",
      });
    }
  }

  toggleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  async delete() {
    const { id } = this.state.groupe;
    await this.props.data.removeItem("groupesParcelles", id);
    this.goToList();
  }

  goToList() {
    this.props.history.replace("/parcellaire");
  }

  render() {
    const isUpdate = this.props.history.location.state.id;
    return (
      <Container>
        <Row className="mb-3">
          <Col>
            <Alert
              variant={isUpdate ? "warning" : "success"}
              className="text-center"
            >
              <Alert.Heading>
                {isUpdate ? "Modifier" : "Créer"} un groupe
              </Alert.Heading>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Nom groupe
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="label"
                    type="text"
                    placeholder="Nom groupe"
                    value={this.state.groupe.label}
                    onChange={this.handleChange}
                  />
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <MapDraw
              ref={(map) => {
                this.map = map;
              }}
              isClickable
              isLegende
              isGeolocation
              dragging={true}
              scrollWheelZoom={true}
              doubleClickZoom={false}
            />
          </Col>
          <Col md={6}>
            <Filters
              pathName="createGroupe"
              listName="selectionParcelles"
              list={this.props.ctx.parcelles}
            >
              <Parcelle isEditable />
            </Filters>
          </Col>
        </Row>
        <Row className="mt-3 mb-3">
          <Col className="d-flex justify-content-end">
            {isUpdate && (
              <Button variant="danger" onClick={this.toggleModal}>
                Supprimer le groupe
                <MdDelete size={25} className="ml-2" />
              </Button>
            )}
            <FloatingSaveButton title="Enregistrer" onClickFn={this.save} />
          </Col>
        </Row>
        <Modal centered show={this.state.isModalOpen} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Êtes-vous certain de vouloir supprimer ce groupe ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.toggleModal}>
              Annuler
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default WithContext(WithParcellesActives(CreateGroupe));
