import React from "react";
// global context (this.props.data)
import { WithContext } from "store/DataProvider";
// react-bootstrap components
import {
  Container,
  Row,
  Col,
  Alert,
  Card,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
// custom components
import Input from "inputs/input";
import FloatingSaveButton from "buttons/FloatingSaveButton";
// react-icons
import { MdDelete } from "react-icons/md";
import Icon from "icons/icon.js";

class createMateriel extends React.Component {
  constructor(props) {
    super(props);
    const id =
      this.props.history.location.state && this.props.history.location.state.id;
    if (id) {
      // // pour tester la modification ...
      // this.state = {
      //   id: 1,
      //   label: "matos 1",
      //   categorieMateriel: [{ id: 1, label: "cat 1" }],
      //   sousCategorieMateriel: [],
      //   typePropriete: [],
      //   coutHoraire: "",
      //   coutHectare: "",
      //   isModalOpen: false,
      // };
      this.state = {
        ...this.props.history.location.state,
        isModalOpen: false,
        inputs,
      };
    } else {
      // pour tester la création ...
      this.state = {
        label: "",
        categorieMateriel: [],
        sousCategorieMateriel: [],
        typePropriete: [],
        coutHoraire: "",
        coutHectare: "",
        isModalOpen: false,
        inputs,
      };
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleReactSelectChange = this.handleReactSelectChange.bind(this);
    this.validate = this.validate.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async componentDidMount() {
    // récupérer les valeurs des listes déroulantes au montage du composant
    const inputs = await this.props.data.sendRequest("listes", {
      ecran: "materiel",
    });
    this.setState((state) => {
      // pour chaque liste déroulante envoyée par le serveur
      Object.keys(inputs).forEach((inputName) => {
        // chercher la liste déroulante correspondante
        const inputIndex = state.inputs.findIndex(
          (input) => input.name === inputName
        );
        if (inputIndex !== -1) {
          // positionner les valeurs des listes
          state.inputs[inputIndex].options = inputs[inputName];
        }
      });
      return state;
    });
  }

  handleChange(e) {
    const target = e.target;
    const type = target.type;
    let value = target.value;
    const name = target.name;
    // pour pouvoir accéder au nativeEvent
    if (type === "select-one") e.persist();

    this.setState((state) => {
      let newValue = value;
      if (type === "select-one") {
        if (value) {
          const nativeTarget = e.nativeEvent.target;
          const index = nativeTarget.selectedIndex;
          const label = nativeTarget[index].text;
          // ajouter l'id et le label de l'option sélectionnée
          newValue = [{ id: Number(value), label: label }];
        } else {
          newValue = [];
        }
      } else if (type === "number" && value !== "") {
        // tronquer au nombre de décimales souhaité
        const decimals = target.getAttribute("decimals");
        newValue = +Number(target.value).toFixed(decimals);
      } else if (type === "radio") {
        newValue = Number(value);
      }
      state[name] = newValue;
      return state;
    });
  }

  handleReactSelectChange(name, option) {
    this.setState((state) => {
      state[name] = [option];
      return state;
    });
  }

  validate() {
    let data = { ...this.state };

    let isValid = true;
    if (!data.label) {
      isValid = false;
      this.showMessage("Vous n'avez pas renseigné le nom du matériel.");
    }
    if (!data.categorieMateriel.length) {
      isValid = false;
      this.showMessage("Vous n'avez pas renseigné la catégorie du matériel.");
    }
    if (!data.sousCategorieMateriel.length) {
      isValid = false;
      this.showMessage(
        "Vous n'avez pas renseigné la sous-categorie du matériel."
      );
    }
    if (!data.typePropriete.length) {
      isValid = false;
      this.showMessage(
        "Vous n'avez pas renseigné le type de propriété du matériel."
      );
    }

    if (isValid) {
      delete data.isModalOpen;
      delete data.inputs;
      console.log("data");
      console.log(data);
      this.save(data);
    }
  }

  async save(item) {
    const { id } = this.state;
    let isSuccess;
    if (id) {
      isSuccess = await this.props.data.updateItem("materiels", item);
    } else {
      isSuccess = await this.props.data.addItem("materiels", item);
    }
    if (isSuccess) {
      this.props.history.replace("/parametrable?requete=ecran&ecran=materiel");
    }
  }

  async delete(e) {
    const { id } = this.state;
    const response = await this.props.data.removeItem("materiels", id);
    if (response.success) {
      this.props.history.replace("/parametrable?requete=ecran&ecran=materiel");
    } else {
      if (response.error === "intervention") {
        this.props.data.showNotification({
          type: "danger",
          title:
            "Impossible de supprimer ce matériel car il est attaché à une intervention existante.",
        });
      }
    }
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  showMessage(message) {
    this.props.data.showNotification({
      type: "warning",
      title: message,
    });
  }

  render() {
    const isUpdate = this.state.id ? true : false;
    return (
      <Container>
        <Row>
          <Alert
            variant={isUpdate ? "warning" : "success"}
            className={"d-flex justify-content-between w-100 mb-0"}
          >
            <Alert.Heading className="d-flex align-items-center">
              {isUpdate ? "Modifier" : "Créer"} un matériel
            </Alert.Heading>
            <Icon
              iconName="../../assets/moissonneuse.svg"
              size={50}
              verticalAlign="inherit"
            />
          </Alert>
        </Row>
        <Row>
          <Card.Body>
            <Form>
              {this.state.inputs.map((input, index) => {
                return (
                  <Input
                    key={index}
                    input={input}
                    value={this.state[input.name]}
                    options={input.options}
                    handleChange={this.handleChange}
                    handleReactSelectChange={this.handleReactSelectChange}
                  />
                );
              })}
            </Form>
          </Card.Body>
        </Row>
        <Row>
          <p>
            En €/hectare pour tout ; sauf pour « Tracteur et Matériel Transport
            » : en €/heure
          </p>
        </Row>
        <Row className="mt-3 mb-3">
          <Col xs={12}>
            {isUpdate && (
              <Button
                variant="danger"
                onClick={this.openModal.bind(this, "supprimer")}
                className="float-right mb-2"
              >
                Supprimer
                <MdDelete size={25} className="ml-2" />
              </Button>
            )}
          </Col>
        </Row>
        <FloatingSaveButton title="Enregistrer" onClickFn={this.validate} />
        <Modal centered show={this.state.isModalOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Êtes-vous certain de vouloir supprimer ce matériel ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Annuler
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                this.closeModal();
                this.delete();
              }}
            >
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default WithContext(createMateriel);

const inputs = [
  {
    type: "text",
    label: "Nom du matériel",
    name: "label",
  },
  {
    type: "react-select",
    label: "Catégorie de matériel",
    name: "categorieMateriel",
    options: [],
  },
  {
    type: "react-select",
    label: "Sous-catégorie de matériel",
    name: "sousCategorieMateriel",
    options: [],
  },
  {
    type: "react-select",
    label: "Type de propriété",
    name: "typePropriete",
    options: [],
  },
  {
    type: "number",
    label: "Coût à l'heure *",
    name: "coutHoraire",
    step: "0,01",
    unit: "€/heure",
  },
  {
    type: "number",
    label: "Coût à l'hectare *",
    name: "coutHectare",
    step: "0,01",
    unit: "€/hectare",
  },
];
