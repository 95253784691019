import React from "react";
// global context (this.props.data)
import { WithContext } from "store/DataProvider";
// create context (this.props.create)
import { WithCreateIntervention } from "interventions/createProvider";
// parcelles actives context (this.props.ctx)
import { WithParcellesActives } from "parcelles/provider";
// intervention datas
import { listeTypesIntervention } from "interventions/datas.js";
// intervention helper
import { Helper } from "interventions/helpers.js";
// react-bootstrap components
import { Container, Row, Alert, Col, Button, Modal } from "react-bootstrap";
// custom component
import Accordions from "interventions/accordions/component";
import FloatingSaveButton from "buttons/FloatingSaveButton";
// react-icons
import Icon from "icons/icon.js";
import { MdNoteAdd, MdDelete } from "react-icons/md";

class CreateIntervention extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      action: "",
    };
    const { id, parcelles } = this.props.history.location.state;
    let existingIntervention;
    if (id) {
      existingIntervention = (props.data.interventions || []).find(
        (intervention) => intervention.id === id
      );
    }
    if (existingIntervention) {
      // deep copy de l'intervention sinon clone
      existingIntervention = JSON.parse(JSON.stringify(existingIntervention));
      // pour les récoltes
      if (Helper.isRecolte(existingIntervention.typeId)) {
        // le serveur peut renvoyer un tableau vide (si l'utilisateur n'avait rien saisi)
        // dans ce cas, le formulaire de saisie n'apparait pas
        if (!existingIntervention.recoltes.length) {
          // rajouter une récolte vide pour faire apparaître le formulaire de saisie
          existingIntervention.recoltes = [
            {
              id: 31,
              label: "Récolte",
              culture: "",
              unit: "T",
              quantite: "",
            },
          ];
        }
      }
      this.props.create.setIntervention(existingIntervention);
      this.props.ctx.setParcellesActives(existingIntervention.parcelles);
    }
    if (parcelles) {
      this.props.ctx.setParcellesActives(parcelles);
    }
    this.isUpdate = id ? true : false;
    this.isEditable =
      !this.isUpdate ||
      (this.isUpdate &&
        existingIntervention &&
        existingIntervention.modifiable === true);
    this.duplicate = this.duplicate.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    if (!this.isEditable) {
      this.props.data.showNotification({
        type: "warning",
        title:
          "Vous ne pouvez pas modifier cette intervention car elle a été créée par un tiers (mode multi-exploitations)",
      });
    }
  }

  validate = async () => {
    if (this.isValid()) {
      let intervention = {
        ...this.props.create.intervention,
        parcelles: this.props.ctx.parcellesActives,
        modifiable: true,
      };
      if (this.isSynchronized(intervention)) {
        intervention = this.removeEmptyItems(intervention);

        // rajouter les cultures
        const cultures = intervention.parcelles.map((p) => p.culture[0]);
        intervention.cultures = cultures;

        if (Helper.isRecolte(intervention.typeId)) {
          // si récolte, rajouter la culture
          const culture = intervention.parcelles[0].culture;
          intervention.recoltes[0].culture = culture;
          const surfaceRecolte = intervention.recoltes[0].quantite;
          const surfaceTotale = this.props.ctx.getSurfaceTotale();
          intervention.recoltes[0].quantitePerSurface = Number(
            surfaceRecolte / surfaceTotale
          ).toFixed(2);
        }

        let promise = intervention.id
          ? this.props.data.updateItem
          : this.props.data.addItem;
        await promise("interventions", intervention);
        this.props.history.replace("/interventions");
      }
    }
  };

  async delete(e) {
    const { id } = this.props.history.location.state;
    await this.props.data.removeItem("interventions", id);
    this.props.history.replace("/interventions");
  }

  async duplicate() {
    const { id } = this.props.history.location.state;
    let intervention = this.props.data.interventions.find(
      (intervention) => intervention.id === id
    );
    await this.props.data.addItem("interventions", { ...intervention });
    this.props.history.replace("/interventions");
  }

  showMessage(message) {
    this.props.data.showNotification({
      type: "danger",
      title: message,
    });
  }

  isValid() {
    let isParcelles =
      this.props.ctx.parcellesActives.length > 0 &&
      this.props.ctx.getSurfaceTotale() > 0;
    if (!isParcelles) {
      this.showMessage(
        "Vous devez sélectionner au minimum une parcelle (ayant une surface > 0 ha)."
      );
      return false;
    }
    if (Helper.isRecolte(this.props.create.intervention.typeId)) {
      // si récolte : une seule culture possible
      let isUniqueCulture = true;
      const cultureId = this.props.ctx.parcellesActives[0].culture[0].id;
      this.props.ctx.parcellesActives.forEach((parcelle) => {
        if (parcelle.culture[0].id !== cultureId) {
          isUniqueCulture = false;
        }
      });
      if (!isUniqueCulture) {
        this.showMessage(
          "Une intervention récolte ne peut comporter qu'une seule culture."
        );
        return false;
      }
    }
    return true;
  }

  isSynchronized(intervention) {
    const isSynchronizedIntervention = !intervention.id || intervention.id >= 0;
    if (!isSynchronizedIntervention) {
      this.showMessage(
        "Cette intervention doit être synchronisée avant de pouvoir être modifiée."
      );
    }
    return isSynchronizedIntervention;
  }

  removeEmptyItems(intervention) {
    ["produits"].forEach((listName) => {
      if (intervention[listName]) {
        intervention[listName] = intervention[listName].filter(
          (item) => item.quantite
        );
      }
    });
    return intervention;
  }

  openModal(action, e) {
    if (!this.isNotSynchronized()) {
      this.setState({ action: action, isModalOpen: true });
    }
  }

  isNotSynchronized(action) {
    const isNotSynchronizedIntervention =
      action === "supprimer" && this.props.history.location.state.id < 0;
    if (isNotSynchronizedIntervention) {
      this.showMessage(
        "Cette intervention doit être synchronisée avant de pouvoir être supprimée."
      );
    }
    return isNotSynchronizedIntervention;
  }

  closeModal() {
    this.setState({ action: "", isModalOpen: false });
  }

  render() {
    const { interventionTypeId } = this.props.history.location.state;
    const typeIntervention = listeTypesIntervention.find(
      (inter) => inter.id === interventionTypeId
    );
    return (
      <Container>
        <Row>
          <Alert
            variant={this.isUpdate ? "warning" : "success"}
            className={"d-flex justify-content-between w-100 mb-0"}
          >
            <div className="d-flex flex-column">
              <Alert.Heading>
                {this.isUpdate ? "Modifier" : "Créer"} une intervention
              </Alert.Heading>
              <p>{typeIntervention.label}</p>
            </div>
            <Icon
              iconName={typeIntervention.icon}
              size={50}
              verticalAlign="inherit"
            />
          </Alert>
        </Row>
        <Row>
          <Accordions
            interventionTypeId={interventionTypeId}
            isUpdate={this.isUpdate}
            isEditable={this.isEditable}
          />
        </Row>
        <Row className="mt-3 mb-3">
          <Col xs={12}>
            {this.isUpdate && (
              <Button
                variant="danger"
                onClick={this.openModal.bind(this, "supprimer")}
                className="float-right mb-2"
                disabled={!this.isEditable}
              >
                Supprimer l'intervention
                <MdDelete size={25} className="ml-2" />
              </Button>
            )}
          </Col>
          <Col xs={12}>
            {this.isUpdate && (
              <Button
                variant="info"
                onClick={this.openModal.bind(this, "dupliquer")}
                className="float-right mb-2"
                disabled={!this.isEditable}
              >
                Dupliquer l'intervention
                <MdNoteAdd size={25} className="ml-2" />
              </Button>
            )}
          </Col>
        </Row>
        {/* <Button
          style={{
            position: "fixed",
            right: "20px",
            bottom: "20px",
            zIndex: 1000,
          }}
          variant="warning"
          onClick={this.validate}
          className="float-right"
          disabled={!this.isEditable}
        >
          Enregistrer l'intervention
          <MdEdit size={25} className="ml-2" />
        </Button> */}
        <FloatingSaveButton
          title="Enregistrer l'intervention"
          onClickFn={this.validate}
          disabled={!this.isEditable}
        />
        <Modal centered show={this.state.isModalOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Êtes-vous certain de vouloir {this.state.action} cette intervention
            ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Annuler
            </Button>
            {this.state.action === "dupliquer" && (
              <Button variant="info" onClick={this.duplicate}>
                Dupliquer
              </Button>
            )}
            {this.state.action === "supprimer" && (
              <Button variant="danger" onClick={this.delete}>
                Supprimer
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default WithContext(
  WithCreateIntervention(WithParcellesActives(CreateIntervention))
);
