// react-bootstrap components
import { Button } from "react-bootstrap";
// react-icons
import { MdEdit } from "react-icons/md";

const FloatingSaveButton = ({ title, onClickFn, disabled }) => (
  <Button
    style={{
      position: "fixed",
      right: "20px",
      bottom: "20px",
      zIndex: 1000,
    }}
    variant="warning"
    onClick={onClickFn}
    className="float-right"
    disabled={disabled}
  >
    {title}
    <MdEdit size={25} className="ml-2" />
  </Button>
);

export default FloatingSaveButton;
