import React from "react";
// global context (this.props.data)
import { WithContext } from "store/DataProvider";
// react-bootstrap components
import { Container, Row, Col, Alert, Button, Modal } from "react-bootstrap";
// custom component
import Accordions from "parcelles/accordions/component";
import FloatingSaveButton from "buttons/FloatingSaveButton";
// react-icons
import { MdDelete } from "react-icons/md";

class CreateParcelle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        show: false,
        title: "",
        body: "",
        cancelBtn: "",
        validateBtn: "",
        validateFn: undefined,
        validateBtnVariant: "",
      },
      surfaceDessin: 0,
      surfaceTelepac: 0,
    };
    const { id } = this.props.history.location.state;
    let parcelleExistante;
    let nouvelleParcelle = {
      type: "Feature",
      properties: {
        label: "",
        surface: "",
        ilot: [],
        typeCulture: [{ id: 1, label: "Principale" }],
        destination: [],
        culture: [],
        varietes: [],
        culturePrecedente: [],
        rendement: "",
        typeDeSol: [],
        zoneVulnerable: 1,
        telepac: [],
        groupes: [],
      },
      geometry: {},
    };
    if (id) {
      parcelleExistante = props.data.parcelles.find(
        (item) => item.properties.id === id
      );
      if (parcelleExistante) {
        // deep copy de la parcelle existante (sinon cette parcelle sera modifiée globalement = app crash)
        parcelleExistante = JSON.parse(JSON.stringify(parcelleExistante));
      }
    }
    if (parcelleExistante) {
      this.state.parcelle = parcelleExistante;
      this.state.cultureId = parcelleExistante.properties.culture[0].id;
      this.state.surfaceDessin = parcelleExistante.properties.surface;
    } else {
      this.state.parcelle = nouvelleParcelle;
      this.state.cultureId = -1;
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleReactSelectChange = this.handleReactSelectChange.bind(this);
    this.setGeometry = this.setGeometry.bind(this);
    this.setSurface = this.setSurface.bind(this);
    this.validate = this.validate.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  handleChange(e) {
    const target = e.target;
    const type = target.type;
    let value = target.value;
    const name = target.name;
    // pour pouvoir accéder au nativeEvent
    if (type === "select-one") e.persist();

    this.setState((state) => {
      let newValue = value;
      if (type === "select-one") {
        if (value) {
          const nativeTarget = e.nativeEvent.target;
          const index = nativeTarget.selectedIndex;
          const label = nativeTarget[index].text;
          // ajouter l'id et le label de l'option sélectionnée
          newValue = [{ id: Number(value), label: label }];
        } else {
          newValue = [];
        }
      } else if (type === "number" && value !== "") {
        // tronquer au nombre de décimales souhaité
        const decimals = target.getAttribute("decimals");
        newValue = +Number(target.value).toFixed(decimals);
      } else if (type === "radio") {
        newValue = Number(value);
      }
      state.parcelle.properties[name] = newValue;
      return state;
    });
  }

  handleReactSelectChange(name, option) {
    this.setState((state) => {
      delete option.color;
      state.parcelle.properties[name] = [option];
      // si changement de valeur liste déroulante culture
      if (name === "culture") {
        // maj de l'id de la culture (pour filtrer la liste déroulante des variétés)
        state.cultureId = option.id;
        // réinitialiser la liste des varietes
        state.parcelle.properties.varietes = [];
      }
      return state;
    });
  }

  setGeometry(data) {
    this.setState((state) => {
      state.parcelle.geometry = data.geometry;
      state.surfaceDessin = data.surface;
      // si modification parcelle
      if (this.props.history.location.state.id) {
        // booleen qui permet de savoir que le contour de la parcelle a été modifié
        state.parcelle.modifContour = true;
      }
      return state;
    });
  }

  setSurface(surface) {
    this.setState((state) => {
      state.parcelle.properties.surface = surface;
      return state;
    });
  }

  validate() {
    let parcelle = { ...this.state.parcelle };
    if (this.isValid(parcelle) && this.isValidContour(parcelle)) {
      this.save();
    }
  }

  isValid(parcelle) {
    let isValid = true;
    if (!parcelle.properties.label) {
      isValid = false;
      this.showError("Vous n'avez pas renseigné le nom de la parcelle.");
    }
    if (!parcelle.properties.surface) {
      isValid = false;
      this.showError("Vous n'avez pas renseigné la surface de la parcelle.");
    }
    if (!parcelle.properties.typeCulture.length) {
      isValid = false;
      this.showError(
        "Vous n'avez pas renseigné le type de culture de la parcelle."
      );
    }
    if (!parcelle.properties.culture.length) {
      isValid = false;
      this.showError("Vous n'avez pas renseigné la culture de la parcelle.");
    }
    return isValid;
  }

  isValidContour(parcelle) {
    if (
      !parcelle.geometry.coordinates ||
      !parcelle.geometry.coordinates.length
    ) {
      this.showModal("contour");
      return false;
    } else {
      return true;
    }
  }

  showError(message) {
    this.props.data.showNotification({
      type: "danger",
      title: message,
    });
  }

  async save() {
    let parcelle = { ...this.state.parcelle };
    parcelle.id_utilisateur = this.props.data.utilisateur.id;
    parcelle.id_campagne = this.props.data.campagneActive.id;
    let exploitation = {
      id: this.props.data.exploitationActive.id,
      label: this.props.data.exploitationActive.label,
    };
    let parcelleProps = parcelle.properties;
    if (parcelleProps.exploitation) {
      parcelleProps.exploitation =
        parcelleProps.exploitation.concat(exploitation);
    } else {
      parcelleProps.exploitation = [exploitation];
    }
    if (parcelleProps.id) {
      await this.props.data.updateItem("parcelles", parcelle);
    } else {
      await this.props.data.addItem("parcelles", parcelle);
    }
    this.goToList();
  }

  showModal(type) {
    let modal = {};
    if (type === "supprimer") {
      modal = {
        show: true,
        title: "Confirmation",
        body: "Êtes-vous certain de vouloir supprimer cette parcelle ?",
        cancelBtn: "Annuler",
        validateBtn: "Supprimer",
        validateFn: () => {
          this.delete();
          this.hideModal();
        },
        validateBtnVariant: "danger",
      };
    } else if (type === "contour") {
      modal = {
        show: true,
        title: "Confirmation",
        body: "Êtes-vous certain de vouloir enregistrer cette parcelle sans contour ?",
        cancelBtn: "Annuler",
        validateBtn: "Enregistrer",
        validateFn: () => {
          this.save();
          this.hideModal();
        },
        validateBtnVariant: "success",
      };
    }
    this.setState({ modal: modal });
  }

  hideModal() {
    this.setState({ modal: { show: false } });
  }

  async delete() {
    const { id } = this.state.parcelle.properties;
    const response = await this.props.data.removeItem("parcelles", id);
    if (response.error && response.error === "intervention") {
      this.props.data.showNotification({
        type: "danger",
        title:
          "La parcelle n'a pas pu être supprimée car elle est attachée à une ou plusieurs interventions.",
      });
    } else {
      this.goToList();
    }
  }

  goToList() {
    this.props.history.replace("/parcellaire");
  }

  render() {
    const isUpdate = this.props.history.location.state.id;
    const nomParcelle = this.state.parcelle.properties.label;
    const { modal } = this.state;
    return (
      <Container>
        <Row>
          <Alert
            variant={isUpdate ? "warning" : "success"}
            className="text-center w-100 mb-0"
          >
            <Alert.Heading>
              {isUpdate
                ? `Modifier la parcelle ${nomParcelle}`
                : "Créer une parcelle"}
            </Alert.Heading>
          </Alert>
        </Row>
        <Row>
          <Accordions
            isUpdate={isUpdate}
            parcelle={this.state.parcelle}
            cultureId={this.state.cultureId}
            surfaceTelepac={this.state.surfaceTelepac}
            surfaceDessin={this.state.surfaceDessin}
            handleChange={this.handleChange}
            handleReactSelectChange={this.handleReactSelectChange}
            setGeometry={this.setGeometry}
            setSurface={this.setSurface}
            history={this.props.history}
          />
        </Row>
        <Row className="mt-3 mb-3">
          <Col className="d-flex justify-content-end">
            {isUpdate && (
              <Button
                variant="danger"
                onClick={() => this.showModal("supprimer")}
              >
                Supprimer la parcelle
                <MdDelete size={25} className="ml-2" />
              </Button>
            )}
            <FloatingSaveButton title="Enregistrer" onClickFn={this.validate} />
          </Col>
        </Row>
        <Modal centered show={modal.show} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title> {modal.title} </Modal.Title>
          </Modal.Header>
          <Modal.Body>{modal.body}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.hideModal}>
              {modal.cancelBtn}
            </Button>
            <Button
              variant={modal.validateBtnVariant}
              onClick={modal.validateFn}
            >
              {modal.validateBtn}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default WithContext(CreateParcelle);
